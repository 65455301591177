import React, { useState, useEffect, useContext, } from 'react';
import CommonNavbar from "../../components/CommonNavbar";
import { ApiDispatchContext } from "../../context/ApiContext";
import { getUrlParameter } from "../../constants/commonFunction";
import GenricPetitionerForm from '../../components/PetitionerForm/GenricPetitionerForm';
import UploadInvoiceFormBottom from '../UploadInvoice/UploadInvoiceFormBottom';
import ErrorAlert from '../../components/ErrorAlert';

const UploadedInvoicePreview = () => {
  const [invoiceFormData, setInvoiceFormData] = useState([]);
  const [gstApplicable, setGstApplicable] = useState(false);
  const [chargesTotal, setChargesTotal] = useState(0);
  const [tdsAmount, setTdsAmount] = useState(0);
  const [cgstAmount, setCgstAmount] = useState(0);
  const [sgstAmount, setSgstAmount] = useState(0);
  const [ugstAmount, setUgstAmount] = useState(0);
  const [totalAmount, setTotalAmount] = useState(0);
  const [modal, setModal] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [modalBody, setModalBody] = useState("");
  const toggle = () => setModal(!modal);
  let invoiceId = Number(getUrlParameter('invoiceId'));
  let caseid = Number(getUrlParameter('case'));
  let casetype = getUrlParameter('caseType');
  const api = useContext(ApiDispatchContext);

  const borderColor = () => {
    return casetype === "opencase" ? " 1px solid #1AB275" : casetype === "allottedcase" ? "1px solid #058AEE" : casetype === "reinvestigatecases" ? "1px solid #EBAF00" : "1px solid #7C144B";
  }

  const type = () => {
    return casetype === "opencase" ? "open" : casetype === "allottedcase" ? "alloted" : casetype === "reinvestigatecases" ? "reinvestigated" : "rejectedInvoice";
  }
  
  const cardClass = () => {
    return casetype === "opencase" ? "open-caseCard" : casetype === "allottedcase" ? "alloted-caseCard" : casetype === "reinvestigatecases" ? "rein-caseCard" : "rejected-caseCard";
  }

  function formColor(){
    return casetype === "opencase" ? "open-case-color" : casetype === "allottedcase" ? "allotted-case-color" : casetype === "reinvestigatecases" ? "rein-case-color" : "reject-case-color";
  }

  const backgroundColor = () => {
    return casetype === "allottedcase" ? "rgba(5, 138, 238, 0.1)" : casetype === "reinvestigatecases" ? "rgba(235, 175, 0, 0.1)" : "rgba(124, 20, 75, 0.1)";
  }

  useEffect(() => {
    api.invoiceIdDetails({ params: { "invoiceId": invoiceId, "caseId": caseid }, withCredentials: true }).then(data=>{
      if(data?.data && data?.data?.success){
        const invoiceData = data?.data?.data; 
        setInvoiceFormData(invoiceData);
        let gstApplicableStatus=""
        for(let i=0; i < invoiceData?.singleInvoiceParameters?.length; i++) {
          if(invoiceData?.singleInvoiceParameters[i]?.block_parameters_id === 6) {
            gstApplicableStatus = invoiceData?.singleInvoiceParameters[i]?.parameter_value
            setGstApplicable(gstApplicableStatus);
          }
        }

        const invoiceDetails = invoiceData?.invoiceDetails;
        setChargesTotal(invoiceDetails?.charge_total);
        setTdsAmount(invoiceDetails?.tds_value);
        setCgstAmount(invoiceDetails?.cgst ? invoiceDetails?.cgst : 0.00);
        setSgstAmount(invoiceDetails?.sgst ? invoiceDetails?.sgst : 0.00);
        setUgstAmount(invoiceDetails?.ugst ? invoiceDetails?.ugst : 0.00);
        setTotalAmount(invoiceDetails?.total_amount);
      } else {
        setModalTitle("Error");
        if(data?.data?.error && data?.data?.message){
          setModalBody(data?.data?.message)
        } else{
          setModalBody('Please try after sometime');
        }
        toggle();
      }
    }).catch(err=>{
      setModalTitle("Error");
      if (err && err?.response && err?.response?.data && err?.response?.data?.message) {
        setModalBody(err?.response?.data?.message);
      } else {
        setModalBody('Please try after sometime.');
      }
      toggle();
    })
  }, [])

  return (
    <div className={"newInvoice-wrapper newUploadInvoiceallottedcase"}>
      <CommonNavbar title="Invoice Preview" navbarname={"AssignedBlocksAllottedCases"} />
      <div className="input-container" style={{marginBottom: '0px'}}>
      {invoiceFormData && invoiceFormData?.singleInvoiceParameters && invoiceFormData?.singleInvoiceParameters?.length > 0 && invoiceFormData?.singleInvoiceParameters?.map((ele, index) => {
          if(index === 0) {
            return <div style={{marginLeft: "10px", paddingTop: "15px"}}> <p style={{fontWeight: 500, fontSize: "14px"}}>Reference # {ele?.parameter_value}</p></div>
          } else if(ele?.parameter_name === "product_category" || (ele?.parameter_name === "gst_number" && gstApplicable == true)) {
            return <GenricPetitionerForm isDisabled={true} ifRequired={true} key={ele?.parameter_name} config={ele} formcolor={backgroundColor()} formName={formColor()} handleChange={() => false} showLabel={true} />
          } else if(ele.parameter_name === "gst_number") {
              if(ele?.parameter_value) {
                return <GenricPetitionerForm isDisabled={true} ifRequired={true} key={ele?.parameter_name} config={ele} formcolor={backgroundColor()} formName={formColor()} handleChange={() => false} showLabel={true} />
              } else {
                return null;
              }
          } else {
            return <GenricPetitionerForm isDisabled={true} ifRequired={true} key={ele?.parameter_name} config={ele} formcolor={backgroundColor()} formName={formColor()} handleChange={() => false} showLabel={true} />
          }
      })}
      {invoiceFormData && invoiceFormData?.multipleInvoiceParameters && invoiceFormData?.multipleInvoiceParameters?.length > 0 && invoiceFormData?.multipleInvoiceParameters?.map((pet,petIndex) => {
         return (
            <div className="multiField-row" key={pet?.parameter_name}>
              <div className="serialNum">
                <label>Sr. No.</label>
                <p>{petIndex+1}</p>
              </div>
              {Array.isArray(pet) && pet.map((petInp) => {
                if(petInp?.parameter_name === "charge_amount") {
                  return <GenricPetitionerForm isDisabled={true} ifRequired={true} key={petInp?.parameter_name} config={petInp} formcolor={backgroundColor()} formName={formColor()} handleChange={() => false} showLabel={true} />
                } else {
                  return <GenricPetitionerForm isDisabled={true} ifRequired={true} key={petInp?.parameter_name} config={petInp} formcolor={backgroundColor()} formName={formColor()} handleChange={() => false} showLabel={true} />
                }
              })}
            </div>
            )
      })}
      <div className="chargesDetail">
          <div className="chargesDetail-row">
            <p>Charge Total</p>
            <p>{chargesTotal}</p>
          </div>
          {tdsAmount > 0&& (
            <div className="chargesDetail-row">
            <p>TDS Deduction</p>
            <p>{tdsAmount}</p>
          </div>
          )}
          {ugstAmount && ugstAmount > 0 ? (
            <div className="chargesDetail-row">
              <p>UGST</p>
              <p>{ugstAmount}</p>
            </div>
          ) : (<>
            <div className="chargesDetail-row">
              <p>CGST</p>
              <p>{cgstAmount}</p>
            </div>
            <div className="chargesDetail-row">
              <p>SGST</p>
              <p>{sgstAmount}</p>
            </div>
          </>)}
          <div className="chargesDetail-row">
            <p>Total Amount</p>
            <p>{totalAmount}</p>
          </div>
        </div>
      </div>
      <UploadInvoiceFormBottom pageName={"UploadedInvoicePreview"} border={borderColor()} onClose={false} docs={invoiceFormData ? invoiceFormData : null} filterToggle={false} showUploadBtn={false} />
      <ErrorAlert
        modal={modal}
        toggle={toggle}
        modalTitle={modalTitle}
        modalBody={modalBody}
      />
    </div>
  )
}

export default UploadedInvoicePreview;