import React, { useState } from "react";
import { Tooltip } from "reactstrap";
import { truncateText } from "../../utils/helper";

function CustomTooltip({ index, value }) {
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const toggle = () => setTooltipOpen(!tooltipOpen);

  return (
    <div>
      <p style={{ marginBottom: 0 }}>
        <div id={`TooltipContent-${index}`}>{truncateText(value)}</div>
      </p>
      <Tooltip
        placement="right"
        isOpen={tooltipOpen}
        target={`TooltipContent-${index}`}
        toggle={toggle}
        style={{ fontSize: "11px" }}
      >
        {value}
      </Tooltip>
    </div>
  );
}

CustomTooltip.args = {
  autohide: true,
  flip: true,
};

CustomTooltip.argTypes = {
  placement: {
    control: { type: "select" },
    options: ["top", "left", "right", "bottom"],
  },
};

export default CustomTooltip;
